<template>
    <Head :title="item.name" >
        <meta name="description" :content="`${item.name} from ${product.retailer.name}`" />
        <meta property="og:title" :content="`${item.name} on ${owner.presentation_name}'s Gift Hero wish list`" />
        <meta property="og:description" :content="`Give and get the perfect gift. Every. Time.`" />
        <meta property="og:url" :content="`${item.open_graph.url}`" />
        <meta property="og:image" :content="`${item.open_graph.share_image}`" />
        <link rel="canonical" :href="`${item.open_graph.url}`" />
        <meta v-if="noIndex" name="robots" content="noindex, follow"/>
    </Head>
    <AppLayout>
        <div class="container-wide flex-stack whitespace-bottom whitespace-top">
            <TransitionGroup name="fade">
                <div class="item-detail__masthead" key="masthead">
                    <div class="item-detail__breadcrumb">
                        <nav class="breadcrumbs breadcrumbs--persistent breadcrumbs--no-margin" key="breadcrumb">
                            <ol>
                                <li class="breadcrumbs__back">
                                    <Link :href="giftlist.url" :title="breadcrumbTitle">
                                        <i class="icon"><SvgIconArrowLeft /></i> Back to {{ giftlist.name }} Wish List
                                    </Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div class="item-detail__share">
                        <!--TODO-->
                        <!--<share-button text="Share Gift" :private="sharePrivate" :share-data="shareData" type="item"></share-button>-->
                    </div>
                </div>
                <article class="item-detail__main" key="item">
                    <!-- Item Image -->
                    <div class="item-detail__visual" :class="{ 'is-purchased': itemsPurchased }">
                        <template v-if="!imageUploadIsVisible">
                            <template v-if="buyVisible">
                                <div class="custom-image__dropzone">
                                    <a :href="product.url" v-bind:data-hostname="retailer.hostname" target="_blank" rel="noopener noreferrer" @click="showConfirmPurchase">
                                        <img :src="item.image_url.original" :key="item.image_url.original" :alt="item.name"/>
                                    </a>
                                </div>
                            </template>
                            <template v-else>
                                <img :src="item.image_url.original" :key="item.image_url.original" :alt="item.name"/>
                            </template>
                        </template>
                        <!-- Only show the change image if the user is the owner of the item -->
                        <template v-if="is_owner">
                            <image-upload v-on:image-uploaded="imageUploaded" v-if="imageUploadIsVisible" />
                            <template v-if="!imageUploadIsVisible">
                                <div class="custom-image__actions">
                                    <button class="btn-link" @click="imageUploadIsVisible = !imageUploadIsVisible">Change Image</button>
                                </div>
                            </template>
                        </template>
                        <template v-if="imageUploadIsVisible">
                            <div class="custom-image__actions">
                                <throbber v-if="isThrobbing"></throbber>
                                <template v-else>
                                    <button class="btn-link" @click.stop="imageUploadIsVisible = false">Cancel</button>
                                    <button class="btn-link btn-link--focus" @click.stop="updateItemImage" :disabled="!imageUploadUuid">Save</button>
                                </template>
                            </div>
                        </template>
                    </div>
                    <!-- Item Detail -->
                    <div class="item-detail__content">
                        <!-- Item Header -->
                        <header class="item-detail__header">
                            <div class="item-detail__header--main">
                                <!-- Product Name -->
                                <h1 class="item-detail__name" v-html="item.name"></h1>
                                <!-- Item Attribution / Non-Owner -->
                                <div class="item-detail__date-added" v-if="!is_owner">
                                    <p>
                                        Added by
                                        <a :href="route('user.giftlists', { username: owner.username })" :title="attributionAddedByTitle">
                                            <img :src="owner.avatar.img_85px_1x" :alt="owner.username" width="26" height="26" :style="{ display: 'inline' }" />
                                        </a>
                                        to
                                        <a :href="giftlist.url" :title="attributionAddedToTitle">{{ giftlist.name }}</a> on
                                        <time :datetime="dayjs(item.created_at).format('YYYY-MM-DD')">{{ dayjs(item.created_at).format("MM/DD/YYYY") }}</time>
                                    </p>
                                </div>
                                <!--  Item Attribution / Owner  -->
                                <div class="item-detail__date-added" v-else-if="is_owner">
                                    <p>
                                        Added to <Link :href="giftlist.url" :title="attributionAddedToTitle">{{ giftlist.name }}</Link>
                                        on
                                        <time :datetime="dayjs(item.created_at).format('YYYY-MM-DD')">{{ dayjs(item.created_at).format("MM/DD/YYYY") }}</time>
                                    </p>
                                </div>
                            </div>
                            <!-- Item Options Menu -->
                            <!-- TODO: Implement item options menu-->
                            <!--<item-options-menu :item="item" :is-owner="is_owner"></item-options-menu>-->
                        </header>
                        <!--Create Account Callout-->
                        <aside class="item-detail__cta item-detail__cta--create-account" v-if="!user">
                            <h3>
                                <template v-if="owner.is_gift_hero">Like this gift?</template>
                                <template v-else>Planning to buy this gift for {{ owner.is_organization ? owner.presentation_name : owner.presentation_name }}?</template>
                            </h3>
                            <template v-if="owner.is_gift_hero">
                                <p>Create an account to save this gift to your wish list.</p>
                            </template>
                            <template v-else>
                                <p>Creating a Gift Hero account will let you confirm your&nbsp;purchase!</p>
                            </template>
                            <div class="actions">
                                <Link class="btn-rounded-alt" :href="route('register')" title="Create a Gift Hero Account">Create an Account</Link>
                                <span>Already signed up? <Link :href="route('login')">Log in</Link>.</span>
                            </div>
                        </aside>
                        <!-- /END callout -->
                        <!-- Item Buy CTA -->
                        <div class="item-detail__cta" v-if="buyVisible">
                            <a :href="product.url" v-bind:data-hostname="retailer.hostname" target="_blank" rel="noopener noreferrer" class="button is-medium is-success is-bold is-caps gtm-item-buy" @click="showConfirmPurchase()">Buy
                                <span class="item-detail__retailer" v-if="retailerVisible">from {{ retailer.name }}</span>
                            </a>
                        </div>
                        <template v-if="giftlist.type === 'expert'">
                            <div class="item-detail__action">
                                <move-copy-item-to-wish-list :item="item" :list-uuid="giftlist.uuid" :is-expert-item="true" />
                            </div>
                        </template>
                        <!-- Item Status -->
                        <div class="item-detail__status">
                            <Transition name="slide" mode="out-in" appear>
                                <!-- Not Editing -->
                                <div class="item-detail__read-group" v-if="!editing">
                                    <div class="status-upper">
                                        <div class="status-upper__content">
                                            <!-- Quantity Requested / Purchased -->
                                            <div class="item-detail__quantity" v-if="quantityVisible" v-html="itemsNeeded"></div>
                                            <!-- Item Price -->
                                            <div class="item-detail__price">
                                                <span class="price-currency-symbol">{{ item.price_symbol }}</span><span class="price-numeric">{{ priceFormatted }}</span><span class="price-currency-code">{{ item.currency_code }}</span><span class="price-per" v-if="multipleItemsRequested">each</span>
                                            </div>
                                        </div>
                                        <!-- Edit Item Button / Owner Only -->
                                        <div class="status-upper__controls" v-if="is_owner && !editing">
                                            <button class="button is-teensy is-primary is-inverted" @click="toggleEdit()">Edit</button>
                                        </div>
                                    </div>
                                    <!-- Confirm Purchase / Purchase Info / Logged In Only -->
                                    <div class="status-lower" v-if="shouldShowConfirmPurchase">
                                        <!-- Confirm Purchase -->
                                        <template v-if="confirmButtonVisible && !itemsPurchased">
                                            <button class="button is-small is-primary is-outlined" @click="showConfirmPurchase()">
                                                Confirm Gift
                                            </button>
                                        </template>
                                        <!-- Purchase Status -->
                                        <template v-if="itemsPurchased">
                                            <span class="item-detail__confirm-content">
                                              <span v-html="itemsPurchasedText"></span>
                                              <button class="button is-teensy is-primary is-inverted has-shadow"
                                                      @click="showUndoPurchase()" v-if="item.qty_purchased">Change</button>
                                            </span>
                                        </template>
                                    </div>
                                    <div>
                                        <p id="more_pricing_info" style="padding: 1em;font-size:12px;line-height: 1.6;">
                                            * Product prices and availability are accurate as
                                            of {{ dayjs(item.created_at).format('MMMM D, YYYY h:mm A') }}
                                            ET and are subject to change. Any price and availability information displayed
                                            on {{ retailer.hostname }} at the time of purchase will apply to the
                                            purchase of this product.</p>
                                    </div>
                                </div>
                                <!-- Editing -->
                                <div class="item-detail__edit-group" v-else>
                                    <!--Item Edit Form Errors-->
                                    <Errors class-modifier="error--wide error--margin-bottom" :passed-errors="errorsArray" />
                                    <!-- Item Edit Form -->
                                    <div class="item-detail__form">
                                        <div class="dialog__content--row">
                                            <label for="item-name" class="form-label">Name</label>
                                            <div class="control">
                                                <input class="input" type="text" name="item-name" autocomplete="off" required v-model="form.name">
                                            </div>
                                        </div>
                                        <div class="dialog__content--row dialog__columns dialog__columns--two-thirds">
                                            <!-- Item Price / Currency -->
                                            <div class="column">
                                                <div class="dialog__columns dialog__columns--two">
                                                    <!--Item price-->
                                                    <div class="column">
                                                        <label class="form-label" for="item-price">Price</label>
                                                        <div class="control">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group__text">{{ item.price_symbol }}</span>
                                                                </div>
                                                                <input type="text" class="input" id="item-price" name="item-price" required pattern="[0-9]+(\.[0-9][0-9]?)?" v-model="form.price" @input="validatePrice" autocomplete="off">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="column column--align-bottom">
                                                        <currency-select :currency-code="form.currency_code" @currency-code-selected="eventCurrencyCodeSelected"></currency-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Item Quantity -->
                                            <div class="column" v-if="!isItemFromExpertList">
                                                <label class="form-label" for="item-quantity">Quantity</label>
                                                <div class="control">
                                                    <input class="input" type="number" name="item-quantity" min="1" pattern="\d*" required v-model="form.qty_desired" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <!--Default currency selector-->
                                        <DefaultCurrencyInlineModalog :currency-code="form.currency_code" :currency-symbol="$page.props.currencies[form.currency_code]" v-show="modalogStore.hasInlineModalog"></DefaultCurrencyInlineModalog>
                                        <!--Price Errors-->
                                        <span v-if="!isValidPrice">
                                            <p class="help is-invalid">Price can only contain numbers and a single period.</p><br>
                                        </span>
                                        <!-- Item Details -->
                                        <div class="dialog__content--row">
                                            <label class="form-label" for="item-4">Gift Details</label>
                                            <!-- TODO: MR: Implement autosize and remove @input listener -->
                                            <div class="control">
                                                <textarea class="textarea" name="item-comments" rows="2" placeholder="For example: 'the blue one' or 'size medium', etc." ref="notesTextareaRef" v-model="form.notes" @input="resizeTextarea"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="status-upper__controls status-upper__controls--right">
                                        <Throbber v-if="form.processing"></Throbber>
                                        <template v-else>
                                            <button class="button is-white" @click="cancelEdit">Cancel</button>
                                            <button class="button is-primary" :disabled="!isItemValid" @click="save">Save</button>
                                        </template>
                                    </div>
                                </div>
                            </Transition>
                        </div>
                        <!-- Item Comments -->
                        <div class="item-detail__comments" v-if="!editing">
                            <template v-if="item.notes && item.notes.length">
                                <div class="item-detail__comments-user">
                                    <a :href="route('user.giftlists', { username: owner.username })">
                                        <img :src="owner.avatar.img_85px_1x" :alt="owner.username" width="30" height="30"/>
                                        <span>{{ owner.presentation_name }}</span>
                                    </a>
                                    says:
                                </div>
                                <div class="item-detail__comments-body">
                                    {{ item.notes }}
                                </div>
                            </template>
                        </div>
                    </div>
                </article><!-- .item-detail -->
            </TransitionGroup>
        </div>
        <!-- Share -->
        <!--<share :sharedata="shareData" :private="sharePrivate" :dialog-visible="shareDialogVisible" @close="hideShareDialog()"></share>-->
    </AppLayout>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { Link, router, useForm, usePage } from '@inertiajs/vue3';
import CurrencySelect from "@/Components/forms/CurrencySelect.vue";
import DefaultCurrencyInlineModalog from "@/Components/Modalogs/DefaultCurrencyInlineModalog.vue";
import ImageUpload from "@/Components/Actions/ImageUpload.vue";
import Regex from '@/utilities/regex';
import dayjs from 'dayjs';
import accounting from 'accounting';
import { useAuthUser } from "@/composables/useAuthUser";
import { useModalogStore } from "@/stores/modalog";
import AppLayout from "@/Layouts/AppLayout.vue";
import SvgIconArrowLeft from '~/svg/icon-arrow-left.svg';
import Throbber from "@/utilities/Throbber.vue";
import { debounce } from "lodash";
import Errors from "@/Components/errors/Errors.vue";
import MoveCopyItemToWishList from "@/Components/Actions/MoveCopyItemToWishList.vue";

// Stores
const modalogStore = useModalogStore();

// Props
const props = defineProps({
    errors: Object,
});

// State
const user = useAuthUser();
const owner = usePage().props.owner?.data || null;
const giftlist = usePage().props.giftlist?.data || null;
const item = computed(() => usePage().props.item?.data ?? null);
const product = usePage().props.item?.data.product || null;
const retailer = usePage().props.item?.data.product.retailer || null;
const confirmPurchaseVisible =ref(false);
const confirmQty = ref(1);
const data = ref({
    list_uuid: '',
    notes: '',
    price: 0,
    qty_desired: 1
});
const editing = ref(false);
const errorsArray = ref(props.errors || []);
const giftlists = ref([]);
const giftlistsLoaded = ref(false);
const imageUploadIsVisible = ref(false);
const imageUploadUuid = ref(null);
const isValidPrice = ref(true);
const itemCached = ref({}); // Cache the original item object to revert when editing is cancelled.
const loading = ref(false);
const notesTextareaRef = ref(null);
const shareData = ref({
    item: {},
    type: 'item'
});
const shareDialogVisible = ref(false);
const undoPurchaseVisible = ref(false);
const isThrobbing = ref(false);

// Hooks
const form = useForm({
    currency_code: item.value.currency_code || item.value.price_symbol || '',
    list_uuid: usePage().props.giftlist?.data.uuid,
    name: item.value.name || '',
    notes: item.value.notes || '',
    price: item.value.price || '',
    qty_desired: item.value.qty_desired || 1,
});

onMounted(() => {
    document.addEventListener('keyup', cancelEditEventHandler);
});

onUnmounted(() => {
    document.removeEventListener('keyup', cancelEditEventHandler);
});

watch(() => props.errors, (newErrors) => {
    errorsArray.value = Object.values(newErrors);
});

// Computed
const attributionAddedByTitle = computed(() => {
    return `Added by ${ owner.username }`;
});

const attributionAddedToTitle = computed(() => {
    return `Added to ${ giftlist.name }`;
});

const breadcrumbTitle = computed(() => {
    return `Back to ${ giftlist.name }`;
});

const buyVisible = computed(() => {
    return !!item.value.product.url.length;
});

const confirmButtonVisible = computed(() => {
    return (item.value.qty_remaining > 0 && isLoggedIn.value && !owner.is_gift_hero);
});

const hasInlineModalog = computed(() => {
    return modalogStore.hasInlineModalog;
});

const isItemFromExpertList = computed(() => {
    return giftlist.type === 'expert';
});

const is_owner = computed(() => {
    return item.value?.is_owner;
});

// TODO: Spoiler fix goes here
const itemsNeeded = computed(() => {
    // If it's your item, always show the number requested
    if (item.value.is_owner) {
        return `${item.value.qty_desired} Requested`;
    }

    // None purchased
    if (quantityReceived.value === 0) {
        return `${item.value.qty_remaining} Requested`
        // All purchased
    } else if (!item.value.qty_remaining) {
        return 'All Have Been Purchased'
        // Partial quantity purchased
    } else {
        return `<span class="count">${quantityReceived.value} <i>of</i> ${item.value.qty_desired}</span> Purchased`
    }
});

const itemsPurchasedByMe = computed(() => {
    let data = [];
    let count = item.value.qty_purchased;
    let i = 0;

    for (i; i <= count; i++) {
        data.push({
            value: i,
            text: i
        })
    }
    return data;
});

const itemsPurchasedByMeText = computed(() => {
    return `You purchased <strong>${item.value.qty_purchased}</strong> of ${quantityReceived.value}`;
});

// TODO: Refactor
const itemsPurchasedText = computed(() => {
    let data = '';

    if (itemsPurchased) {
        if (multipleItemsRequested) {
            // multiple requested
            if (item.value.qty_purchased) {
                // I purchased some
                data = `You purchased <strong>${item.value.qty_purchased}</strong> of this gift.`;
            } else {
                // I did not purchase any
                data = itemsNeeded;
            }
        } else {
            // one requested
            if (item.value.qty_purchased) {
                // I purchased it
                data = `You purchased <strong>1</strong> of this gift.`
            } else {
                // Someone else purchased it
                data = itemsNeeded;
            }
        }
    }

    return data;
});

const itemsRemaining = computed(() => {
    let data = [];
    let count = item.value.qty_remaining;
    let i = 1;

    for (i; i <= count; i++) {
        data.push({
            value: i,
            text: i
        });
    }
    return data;
});

const multipleItemsPurchased = computed(() => {
    return item.value.qty_purchased > 1;
});

const multipleItemsRemaining = computed(() => {
    return item.value.qty_remaining > 1;
});

const multipleItemsRequested = computed(() => {
    return item.value.qty_desired > 1;
});

const isItemValid = computed(() => {
    return (form.qty_desired > 0 && isValidPrice.value && form.name !== '') || form.processing;
});

const isLoggedIn  = computed(() => {
    return usePage().props.auth.user !== null;
});

const itemsPurchased = computed(() => {
    return item.value.qty_purchased > 0;
});

const priceFormatted = computed(() => {
    if (item.value.price > 0) {
        return item.value.price
    } else {
        return 0
    }
});

const quantityReceived = computed(() => {
   return Number(item.value.qty_desired - item.value.qty_remaining);
});

const quantityVisible = computed(() => {
    return !owner.is_gift_hero && !isItemFromExpertList.value;
});

const retailerVisible = computed(() => {
    return retailer?.name !== 'custom_item';
});

// TODO: Pinia
// const selectedCurrency = computed(() => {
//     return this.$store.state.currencies.selectedCurrency
// });

// const sharePrivate = computed(() => {
//     return this.dataLoaded && this.item.giftlist.privacy == 'private' && !this.item.giftlist.is_owner
// });

const noIndex = computed(() => {
    return giftlist.privacy === 'private' || (!owner.is_gift_hero && !owner.is_organization);
});

const shouldShowConfirmPurchase = computed(() => {
    return isLoggedIn.value && !isItemFromExpertList.value;
});

const undoPurchaseHeadline = computed(() => {
    return item.value.qty_purchased > 1 ? 'Update quantity purchased?' : 'Remove this item?';
});

const undoPurchaseButtonPositive = computed(() => {
    return item.value.qty_purchased > 1 ? 'Update' : 'Yes';
});

const undoPurchaseButtonNegative = computed(() => {
    return item.value.qty_purchased > 1 ? 'Cancel' : 'No';
});

const imageUploaded = (photo_uuid) => {
    imageUploadUuid.value = photo_uuid;
};

const itemPluralized = computed(() => {
    return (item.value.qty_desired > 1) ? 'gifts' : 'gift';
});

const quantities = computed(() => {
    let data = [];
    let minimumAmount = (quantityReceived.value > 0) ? quantityReceived.value : 1;
    for (let i = minimumAmount; i < 100; i++) {
        data.push(i);
    }
    return data;
});

const recalculateQuantities = computed(() => {
    // if (this.data.qty_desired !== this.item.qty_desired) {
    //     // if the user has increased the qty desired
    //     if (this.data.qty_desired > this.item.qty_desired) {
    //         // find the difference and add it to the qty remaining
    //         this.$set(this.item, 'qty_remaining', (parseInt(this.item.qty_remaining) + (this.data.qty_desired - this.item.qty_desired)))
    //     } else {
    //         // if the user has decreased the qty desired
    //         // if the new qty desired is less than or equal to the number remaining, set remaining to 0
    //         if (this.data.qty_desired <= this.item.qty_remaining) {
    //             this.$set(this.item, 'qty_remaining', this.data.qty_desired)
    //         } else {
    //             // otherwise set it to the new remaining amount
    //             this.$set(this.item, 'qty_remaining', 0)
    //         }
    //     }
    //
    //     // Set the new qty_desired amount
    //     this.$set(this.item, 'qty_desired', this.data.qty_desired)
    // }
});

function cancelEdit() {
    editing.value = false;
    modalogStore.hasInlineModalog = false;
    form.reset();
    validatePrice(); // Do we actually need this?
    errorsArray.value = [];
};

// Event Listeners
function cancelEditEventHandler(event) {
    if (event.key === 'Escape') {
        cancelEdit();
    }
}

function eventCurrencyCodeSelected(currencyCode) {
    form.currency_code = currencyCode;
    modalogStore.hasInlineModalog = true;
}

function resizeTextarea() {
    notesTextareaRef.value.style.height = 'auto';
    notesTextareaRef.value.style.height = (notesTextareaRef.value.scrollHeight + 3) + 'px';
};
function save() {
    form.put(route('item.update', { item: usePage().props.item.data.uuid }),    {
        preserveScroll: true,
        onSuccess: () => {
            editing.value = false;
            router.reload({ only: ['item'] });
            setTimeout(() => {
                form.reset();
            }, 0);
        },
    });
};

function showConfirmPurchase() {
    if (isLoggedIn.value && !user.is_gift_hero && item.value.qty_remaining > 0) {
        modalogStore.showModalog({
            type: 'ModalogConfirmPurchase',
            props: { item: item.value },
            isConfirmDisabled: false,
        });
    }
};

function showUndoPurchase() {
    confirmQty.value = (item.value.qty_desired > 1) ? item.value.qty_purchased : 0;

    modalogStore.showModalog({
        type: 'ModalogConfirmPurchase',
        props: {
            isOwner: is_owner,
            item: item.value,
            isUnconfirm: true,
        }
    });
};

const toggleEdit = () => {
    editing.value = !editing.value;
    modalogStore.hasInlineModalog = false;
};

const updateItemImage = () => {
    isThrobbing.value = true;

    axios
        .post(route('item.update_photo', {
            item: item.value.uuid,
            temp_photo_uuid: imageUploadUuid.value
        }))
        .then(() => {
            imageUploadUuid.value = null;
            imageUploadIsVisible.value = false;
            router.reload({ only: ['item'] });
        })
        .finally(() => {
            isThrobbing.value = false;
            imageUploadIsVisible.value = false;
        });
    ;
}

const validatePrice = debounce(function () {
    isValidPrice.value = form.price.search(Regex.priceInputs) >= 0;
}, 500);
</script>
