import './bootstrap';
import '../css/app.css';
import '../scss/app.scss'; // Sass
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { createPinia } from 'pinia';
import { Head, Link } from '@inertiajs/vue3';
import BaseLayout from './Layouts/BaseLayout.vue';
import mitt from 'mitt';
import ModalogBlockUnblockUserConfirmation from '@/Components/Modalogs/BlockUnblockUserConfirmationModalog.vue';
import ModalogAddGift from '@/Components/Modalogs/AddGift/ModalogAddGift.vue';
import ModalogConfirmPurchase from '@/Components/Modalogs/ConfirmPurchaseModalog.vue';
import ModalogConfirmRemoveWishlistImage from '@/Components/Modalogs/ConfirmRemoveWishlistImageModalog.vue';
import ModalogContent from '@/Components/Modalogs/ContentModalog.vue';
import ModalogCreateEditWishList from '@/Components/Modalogs/CreateEditWishListModalog.vue';
import ModalogDeleteAccount from '@/Components/Modalogs/DeleteAccountModalog.vue';
import ModalogDeleteAccountConfirmation from '@/Components/Modalogs/DeleteAccountConfirmationModalog.vue';
import ModalogDeleteItem from '@/Components/Modalogs/DeleteItemModalog.vue';
import ModalogDeleteWishList from "@/Components/Modalogs/DeleteWishListModalog.vue";
import ModalogEditUserProfile from "@/Components/Modalogs/EditUserProfileModalog.vue";
import ModalogMastheadImageUpload from "@/Components/Modalogs/MastheadImageUploadModalog.vue";
import ModalogMoveCopyItemToNewWishList from "@/Components/Modalogs/ModalogMoveCopyItemToNewWishList.vue";
import ModalogMoveCopyItemToWishList from '@/Components/Modalogs/MoveCopyItemToWishListModalog.vue';
import ModalogUnfollowConfirmation from "@/Components/Modalogs/UnfollowConfirmationModalog.vue";
import ModalogWishListItemsReorder from "@/Components/Modalogs/WishListItemsReorderModalog.vue";
import ModalogListSpoilerConfirmation from "@/Components/Modalogs/ListSpoilerConfirmationModalog.vue";
import ModalogUserProfileHeaderImageUpload from "@/Components/Modalogs/UserProfileHeaderImageUploadModalog.vue";
import ModalogConfirmRemoveHeaderImage from "@/Components/Modalogs/ConfirmRemoveHeaderImageModalog.vue";
import ModalogUserProfilePhotoUpload from "@/Components/Modalogs/UserProfilePhotoUpload.vue";
import Modalog from './Components/Modalogs/Modalog.vue';
import * as Sentry from '@sentry/vue';
import Vapor from 'laravel-vapor';
import vClickOutside from 'click-outside-vue3';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
const emitter = mitt();
const pinia = createPinia();

createInertiaApp({
    id: 'gifthero',
    config: {
        performance: true,
        globalProperties: {
            $route: route,
            $pinia: pinia,
            $emitter: emitter,
        }
    },
    title: (title) => `${title} - ${appName}`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
        let page = pages[`./Pages/${name}.vue`];
        page.default.layout = page.default.layout || BaseLayout;

        return page;
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        // Set global properties
        app.config.globalProperties = {
            $route: route,
            $pinia: pinia,
            $emitter: emitter,
        };

        // Sentry init
        if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_APP_ENV !== 'local') {
            Sentry.init({
                app,
                debug: import.meta.env.VITE_APP_DEBUG,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                environment: import.meta.env.VITE_APP_ENV,
                // integrations: [
                //     Sentry.browserTracingIntegration(),
                //     Sentry.replayIntegration({
                //         maskAllText: false,
                //         blockAllMedia: false,
                //     }),
                // ],
                tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
                // tracePropagationTargets: ['localhost', 'local.gifthero.com'],
            });
        }

        app.use(plugin)
            .use(pinia)
            .use(ZiggyVue, Ziggy)
            .directive('clickOutside', vClickOutside.directive)
            .component('Head', Head)
            .component('Link', Link)
            .component('Modalog', Modalog)
            .component('ModalogAddGift', ModalogAddGift)
            .component('ModalogBlockUnblockUserConfirmation', ModalogBlockUnblockUserConfirmation)
            .component('ModalogConfirmRemoveWishlistImage', ModalogConfirmRemoveWishlistImage)
            .component('ModalogContent', ModalogContent)
            .component('ModalogCreateEditWishList', ModalogCreateEditWishList)
            .component('ModalogConfirmPurchase', ModalogConfirmPurchase)
            .component('ModalogListSpoilerConfirmation', ModalogListSpoilerConfirmation)
            .component('ModalogDeleteAccount', ModalogDeleteAccount)
            .component('ModalogDeleteAccountConfirmation', ModalogDeleteAccountConfirmation)
            .component('ModalogDeleteItem', ModalogDeleteItem)
            .component('ModalogDeleteWishList', ModalogDeleteWishList)
            .component('ModalogEditUserProfile', ModalogEditUserProfile)
            .component('ModalogMastheadImageUpload', ModalogMastheadImageUpload)
            .component('ModalogMoveCopyItemToNewWishList', ModalogMoveCopyItemToNewWishList)
            .component('ModalogMoveCopyItemToWishList', ModalogMoveCopyItemToWishList)
            .component('ModalogUnfollowConfirmation', ModalogUnfollowConfirmation)
            .component('ModalogWishListItemsReorder', ModalogWishListItemsReorder)
            .component('ModalogUserProfileHeaderImageUpload', ModalogUserProfileHeaderImageUpload)
            .component('ModalogConfirmRemoveHeaderImage', ModalogConfirmRemoveHeaderImage)
            .component('ModalogUserProfilePhotoUpload', ModalogUserProfilePhotoUpload)
            .mixin({
                methods: { asset: window.Vapor.asset }
            })
            .mount(el);

        return { app, pinia };
    },
    progress: {
        color: '#2484c6',
    }
});

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
window.Vapor = Vapor;
