import { getCurrentInstance } from "vue";

// On the App.vue
//import mitt from 'mitt';
//const emitter = mitt();
//app.config.globalProperties.$emitter = emitter;

export default function useEmitter() {
    const instance = getCurrentInstance();
    const emitter = instance.appContext.config.globalProperties.$emitter;

    return emitter;
}
